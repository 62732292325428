<template>
    <div class="section-questions">
        <h2>Have questions or ready to order?</h2>

        <BButton variant="primary" class="order-now" @click="$emitter.emit('openOrderNow')">Order Now</BButton>

        <p class="text-phone">
            <ClientOnly>
                <template #fallback>
                    <a data-calltrk-noswap href="tel:(888) 816-7468">(888) 816-7468</a>
                </template>
                <a href="tel:(888) 816-7468">(888) 816-7468</a>
            </ClientOnly>
        </p>

        <a href="mailto:info@flameoffcoatings.com?bcc=flameoffdev@gmail.com&subject=[flameoffcoatings.com] General" class="mailto">info@flameoffcoatings.com</a>
    </div>
</template>

<script lang="ts" setup>
    const swap = useSwap();
    const { $emitter } = useNuxtApp();

    onMounted(() => {
        swap.swapContainer();
    });
</script>

<style lang="scss" scoped>
    @import '@/assets/styles/bootstrap/common';
    @import '@/assets/styles/_mixins';

    .section-questions {
        @include top-margin-space;
        @include top-bottom-padding-space(0.65);
        padding-left: 15px;
        padding-right: 15px;

        @media(max-width: 1500px) {
            background-size: 50%, 50%;
        }

        background-color: #1c2d4a;
        background-image: url(/images/spray_hand_left.webp), url(/images/spray_hand_right.webp);
        background-repeat: no-repeat, no-repeat;
        background-position: 0 0, 100% 0;
        background-size: contain, contain;
        text-align: center;
        color: #fff;

        h2 {
            @include font-source-sans-pro-regular(32px);
        }

        .order-now {
            margin: 39px 0 53px;
        }

        .text-phone {
            @include font-open-sans-regular(32px);
            letter-spacing: 1px;
            margin-bottom: 21px;

            a {
                color: #fff;
                font-size: 18px;
                text-decoration: none;
            }
        }

        .mailto {
            font-family: 'Open Sans';
            text-decoration: none;
            color: #fff;
        }

        a {
            &:hover, &:focus {
                color: darken(#fff, 15%);
            }
        }
    }
</style>